import React from "react"

import Layout from "../components/layout/layout"
import Image from "../components/image"
import PuxMetaTags from "../components/shared/PuxMetaTags"

const TextPage = () => (
    <Layout>
        <div className='pux-container'>
            <h1>H1 Proxima Nova Black</h1>
            <h2>H1 Proxima Nova Black</h2>
            <h3>H1 Proxima Nova Black</h3>
            <h4>H1 Proxima Nova Black</h4>
            <h5>H1 Proxima Nova Black</h5>

            <div className='perex'>
                Perex Proxima Nova Light. Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In ac auctor felis. Quisque id auctor nulla, sed lobortis ipsum. Pellentesque erat orci, finibus nec odio vel, malesuada euismod turpis. Sed id sem lorem. Quisque consequat leo at mattis placerat. Pellentesque tincidunt magna nulla, non pellentesque mi viverra ac.
        </div>

            <div className='row'>
                <div className='col-6'>
                    <ul>
                        <li>Duis varius, dui eu bibendum lacinia</li>
                        <li>Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In ac auctor felis. </li>
                        <li>Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In ac auctor felis. </li>
                    </ul>
                </div>
                <div className='col-6'>
                    <ol>
                        <li>Duis varius, dui eu bibendum lacinia</li>
                        <li>Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In ac auctor felis. </li>
                        <li>Mi at ultrices cursus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; In ac auctor felis. </li>
                    </ol>
                </div>
            </div>
            <div>
                <a href="" className='btn btn-primary'>primary button</a><br /><br />
            </div>
            <div>
                <a href="" className='btn btn-default'>secondary button</a><br /><br />
            </div>
            <div>
                <a href="" className='btn btn-primary btn-big'>big button</a><br /><br />
            </div>
            <div>
                <a href="" className='btn-link'>link button</a><br /><br />
            </div>
            <div>
                <a href="">Text link</a><br /><br />
            </div>
            <br /><br />
            <div>
                <input id='cb' name='cb' type='checkbox'></input>
                <label htmlFor='cb'>Checkbox</label>
            </div>
            <br /><br />
            <div>
                <input id='radio' name='radio' type='radio'></input>
                <label htmlFor='radio'>Radiobutton</label>
                <input id='radio2' name='radio' type='radio'></input>
                <label htmlFor='radio2'>Radiobutton</label>
            </div>
            <br /><br />
            <div>
                <label>Text</label>
                <input type='text'></input>
                <label>Area</label>
                <textarea></textarea>
                <label>submit</label>
                <input type='submit'></input>
            </div>
        </div>
    </Layout>
)

export default TextPage
